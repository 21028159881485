
import React, { 
  Suspense,
  StrictMode,
  Component,
  useEffect,
  useState } from "react";

import {
  Route,
  BrowserRouter,
  useLocation,
  withRouter,
  Switch,
} from "react-router-dom";

import Home from "./Pages/Home";

import { Provider } from "react-redux";

import configureStore from "./services/configureStore";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={null}>
        <BrowserRouter>
            <Route exact path="/" component={Home} />
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
}

export default App;
