import React, { useState, useEffect } from 'react'
import Accordion from "react-bootstrap/Accordion";
import { Card } from "react-bootstrap";

import StakeDetailCard from "./StakeDetailCard";
import StakeDetail from "./StakeDetail";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getPNContractInfo } from "../../services/actions";

function StakeWidget(props) {
  const { user } = props.user;
  const data = props.vaults[props.contractAddr]

  useEffect(() => {
    if (props.contractAddr != 0 && props.contractAddr > 0) {
      props.getPNContractInfo(data, user.eth_address);
      let interval = setInterval(() => {
        props.getPNContractInfo(data, user.eth_address);
      }, 30000);
      return () => {
        clearInterval(interval);
      }
    }
  }, [])

  return (
    <>
      {props.contractAddr != 0 ?
        <Accordion className="my-4">
          <Card className="accordion_card my-4">
            <Card.Header className="p-0 mt-0">
              <StakeDetailCard eventKey="0" data={data}></StakeDetailCard>
            </Card.Header>
            {(props.contractAddr > 0) &&
              <Accordion.Collapse eventKey="0">
                <StakeDetail
                  data={data}
                />
              </Accordion.Collapse>
            }
          </Card>

        </Accordion>
        : null}
    </>
  )
}


const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  vaults: (state) => state.vaults,
});

const mapDisactcProps = { getPNContractInfo };
export default connect(structuredSelector, mapDisactcProps)(StakeWidget);

