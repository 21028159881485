import React from 'react'
import PropTypes from 'prop-types'
import * as constants from "../../../services/constants"

function NetworkError(props) {
    return (
        <>
         <div className="modal fade show" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style={{display: 'block'}}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="heading font-20 modal-title" id="exampleModalLabel">Please Change Network</h5>
              <button type="button" className="btn text-white p-0" style={{fontSize: '40px'}} data-bs-dismiss="modal" aria-label="Close" onClick={()=>{props.close(false)}}><i className="bi bi-x text-whtie" /></button>
            </div>
            <div className="modal-body">
              <div className="mb-3" style={{maxHeight:'500px',overflow:"auto"}}>
                <p className="text-white"> You are connected to the wrong network. Please go to your wallet settings, select the {constants.ETH_ENV =='development' ? "Rinkeby" : "Main"} network, and refresh this page.
                </p>
              </div>
            </div>
            <div className="modal-footer border-bottom-0 border-top-0">
               
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
        </>
    )
}

NetworkError.propTypes = {

}

export default NetworkError

