import React from 'react'
import Login from "../Login"
import Dashboard from "../Dashboard"

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

function Home(props) {
    const { user } = props;

    return (
        <>
         {user.login ? <Dashboard/> : <Login/>}         
        </>
    )
}


const structuredSelector = createStructuredSelector({
    user: (state) => state.user,
  });

  const mapDisactcProps = {  };
export default connect(structuredSelector, mapDisactcProps)(Home);

