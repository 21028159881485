import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import BannerArea from "../../component/BannerArea";

import { metamaskLogin } from "../../services/actions";


import Header from "../../component/Header"

import Stake from "../../component/Stake"
import Footer from "../../component/Footer";

// import {} from "../../assets/img/"

const Dashboard = (props) => {
  const { user } = props;
  useEffect(() => {
    if(!user.login){
      props.history.push("/");
    }
  });

  return (
    <div className="main_div">
      <Header />
      <BannerArea />

      <section className="page-content">
      <div className="container">
      <div className="row">
          <div className="col-md-9 py-5 text-dark">
            <h3 className=" text-dark dashboard_heading" style={{fontSize:'40px'}}>
            Welcome to the Rate Vaults Beta
            </h3>
            <p className="main_text"><b>Stake your qualifying NFTs here to earn rewards.</b> Specific NFTs can only be staked in their corresponding Vaults, for example, a Series II NFT cannot be staked in a Series IV Vault. You can stake as many NFTs as you like, although all must be unstaked.</p>
            <p className="main_text main_text_bg p-3">Bonuses increase your rewards by the indicated amount. Each Vault’s bonus directly correlates to how few people have Staked so far in that vault. The more people, the smaller the bonus. </p>
          </div>
        </div>

       
        <div className=" pb-5">
          
          {/* <Stake /> */}
          <Stake />
          {/* <Stake/> */}

        </div>
      </div>
  
      </section>

    <Footer />

    </div>
    // </div>
  );
};

const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
});
const mapDisactcProps = { metamaskLogin };
export default connect(structuredSelector, mapDisactcProps)(Dashboard);

