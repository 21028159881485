import React, { useContext, useState } from 'react'
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

function StakeDetailCard({ children, eventKey, callback, data }) {
  const [useVaultImage, setVaultImage] = useState('/assets/img/pryamid_off.jpeg')
  const currentEventKey = useContext(AccordionContext);

  const [accordtionOpen, SetAccordtionOpen] = useState(false)
  const [vaultColorCode, setVaultColorCode] = useState('#fff')
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => {
      callback && callback(eventKey)

      SetAccordtionOpen(!accordtionOpen)

      if (accordtionOpen) {
        setVaultColorCode('#fff')
        setVaultImage('/assets/img/pryamid_off.jpeg')

      } else {
        setVaultColorCode('#FEC5FF')
        setVaultImage(data.stakeNFT > 0 ? '/assets/img/pryamid_danger.gif' : '/assets/img/pryamid_normal.gif');
      }

    }
  );

  const formatCash = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };


  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <>
    <div className=  {data.stakeNFT > 0 ?"accordion_stack" : "" }>
      <div className={isCurrentEventKey && data.contractAddr > 0 ? "card vault_card vault_card_active" : "card vault_card "} 
      
      onClick={decoratedOnClick}>
        <div className={data.stakeNFT > 0 ? "card-body active" : "card-body"} style={{ cursor: (data.contractAddr > 0 ? "pointer" : "default") }} onClick={() => { (data.contractAddr > 0 ? decoratedOnClick() : "") }}
        >
          <div className="row d-flex align-items-center">
            <div className="col-md-6 col-lg-2 col-sm-12  stake_img_holder">
             
            <img src={data.image} width="150" />
            </div>
            <div className="col-md-6 col-lg-4 col-xl-5 col-sm-12 py-3 stake_vault_name">
              <div className="stake_title_name">
                {/* <h4 className={data.stakeNFT > 0 ? "vault_text active" : "vault_text"}>RATE Vault</h4> */}
                <h2 className={data.stakeNFT > 0 ? "heading2 active d-block text-left" : "heading2 d-block text-left"}>{data.name}</h2>
              </div>
            </div>

            {data.contractAddr > 0 ?
              <>
                {/* <div className="col-md-3 col-lg-2 py-3 col-sm-4 stake_title_holder ">
               <div className ="stake_title">
                  <h6 className="muted-text"> CURRENT <br/>RATE REWARDS</h6>
                  <h3 className="heading3">{data.stakeTotal> 0 ? data.pntReward : "None"}</h3>
                </div> 
              </div> */}
                {/* <div className="col-md-3 col-lg-2 col-sm-3 py-3 stake_title_holder">
                 
                  <h6 className="muted-text">CURRENT <br /> PNT REWARDS</h6>
                  <h3 className="heading3">9999999</h3>
                </div> */}
                <div className="col-md-3 col-lg-2 py-3 col-sm-3 stake_title_holder ">
                  <div className="stake_title">
                    <h6 className="muted-text"> TOTAL <br />STAKED NFTS</h6>
                    <h3 className="heading3">{data.stakeTotal} of {data.nftTotal}</h3>
                  </div>
                </div>
                <div className="col-md-3 col-lg-2 col-xl-1 py-3 col-sm-3 stake_title_holder ">
                  <div className="stake_title">
                    <h6 className="muted-text"> CURRENT<br /> BONUS</h6>
                    <h3 className="heading3 bonus_text">{data.stakeTotal > 0 ? data.bonus : "N/A"}</h3>
                  </div>
                </div>
                <div className="col-md-3 col-lg-2 col-sm-3 py-3 d-flex stake_title_holder">

                  <div className="stake_title float-start">
                    <h6 className="muted-text"> YOUR CLAIMABLE<br /> RATE REWARDS</h6>
                    <h3 className="heading3">{formatCash(data.ownPNT)}</h3>
                  </div>

                  <button
                    type="button"
                    style={{ backgroundColor: "transparent", border: "unset" }}
                    className="accordtionBtn ps-4"
                  >
                    {isCurrentEventKey ? (
                      <i className=" text-white bi bi-chevron-down"></i>
                    ) : (
                      <i className="  bi bi-chevron-left"></i>
                    )}
                  </button>
                </div>
              </>
              :
              <div className="col-md-12 col-lg-5 col-sm-12 text-center">
                <h2 className="heading  text-muted  font-20 d-block">Vaulting Coming Soon</h2>
              </div>
            }
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default StakeDetailCard
