import ReactGA from "react-ga";
export const ETH_ENV = process.env.ETH_ENV;
export const DOMAIN_NAME = process.env.DOMAIN_NAME;
export const GOOGLE_ANALYTICS_ID = process.env.GOOGLE_ANALYTICS_ID

export const RATE_ADDR = process.env.RATE_ADDR

export const PN_VAULT_1_ADDR = process.env.PN_VAULT_1_ADDR
export const PN_VAULT_2_ADDR = process.env.PN_VAULT_2_ADDR
export const PN_VAULT_3_ADDR = process.env.PN_VAULT_3_ADDR
export const PN_VAULT_4_ADDR = process.env.PN_VAULT_4_ADDR

export const ERC721_STAKING_ADDR_1 = process.env.ERC721_STAKING_ADDR_1
export const ERC721_STAKING_ADDR_2 = process.env.ERC721_STAKING_ADDR_2
export const ERC721_STAKING_ADDR_3 = process.env.ERC721_STAKING_ADDR_3
export const ERC721_STAKING_ADDR_4 = process.env.ERC721_STAKING_ADDR_4

export const PN_VAULT_1_POOL = process.env.PN_VAULT_1_POOL
export const PN_VAULT_2_POOL = process.env.PN_VAULT_2_POOL
export const PN_VAULT_3_POOL = process.env.PN_VAULT_3_POOL
export const PN_VAULT_4_POOL = process.env.PN_VAULT_4_POOL

export const PN_STAKED_1_POOL = process.env.PN_STAKED_1_POOL;
export const PN_STAKED_2_POOL = process.env.PN_STAKED_2_POOL;
export const PN_STAKED_3_POOL = process.env.PN_STAKED_3_POOL;
export const PN_STAKED_4_POOL = process.env.PN_STAKED_4_POOL;

export const INTERNAL_BONUS = process.env.INTERNAL_BONUS;


export const FAQ_URL = process.env.FAQ_URL;

// Google Analytics
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
} else {
  ReactGA.initialize('test', { testMode: true, debug: true })
}

export const React_GA = ReactGA;

export const GPageView = (title,path)=>{
  if(window.gtag){ 
    window.gtag('config', GOOGLE_ANALYTICS_ID, {
      'page_title' : title,
      'page_path': path
    });
  }
}

export const GPageEvent = (name,label,category,)=>{
  if(window.gtag){ 
    window.gtag('event', name, {
      'event_label': label,
      'event_category': category,
      'non_interaction': true
    }); 
  }
}


export const GScreenView = (screen_name)=>{
  if(window.gtag){ 
    window.gtag('event', 'screen_view', {
      'app_name': 'Ape Island Season 2',
      'screen_name' : screen_name
    });
  }
}

// End of Google analytics
