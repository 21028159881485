// import {web3} from 'web3'
import {postReq } from './base';

import detectEthereumProvider from "@metamask/detect-provider";
import * as contents from "../constants"
import PNERC721 from "../abi/PNERC721"
import ERC721STAKING from "../abi/ERC721STAKING"
import RATE from "../abi/RATE"



export const web3 = new Web3();
// window.web3Obj = web3;
const ethereum = window.ethereum


export function metamaskLogin() {
  return (dispatch) => {
    window.ethereum.request({ method: "eth_requestAccounts" }).then((accounts) => {
      return metamask_login_chk(dispatch);
    });
  };
}

function metamask_login_chk(dispatch) {
  ethereum.on('accountsChanged', function (accounts) {
    window.location.reload();
  });
  ethereum.on('chainChanged', function (accounts) {
    window.location.reload();
  });

  if (ethereum.isMetaMask && ethereum.selectedAddress != (null || undefined)) { 
      detectEthereumProvider().then(provider=>{
        window.provider = provider;
        web3.setProvider(provider);
        web3.eth.net.getNetworkType().then(network_type => {
          if((contents.ETH_ENV =='development' && network_type !='main') || (contents.ETH_ENV !='development' && network_type =='main')){ 
            let loggedUser ={};
            loggedUser['eth_address']= ethereum.selectedAddress;
            user_register(loggedUser,dispatch)

          }else{
            dispatch({
              type: 'LOGIN_ERROR_CALL_NETWORK',
              json: {}
            })
          }
        })
      });
    } 

}

export function reset_rate_reward(token_address,dispatch) {
    unsubscribeRateEvent(token_address);
    dispatch({
      type: 'RESET_RATE_REWARD',
      json:{token_address:token_address}
    })
}
function read_event_rate_transfer(eth_address,token_address,staking_address,dispatch){
  let contract = new web3.eth.Contract(RATE,contents.RATE_ADDR);
  let event = contract.events['Transfer']().on('data',async(ev)=>{
    let returnValues = ev.returnValues;
    if(returnValues['from'].toLocaleLowerCase() == staking_address.toLocaleLowerCase()  && returnValues['to'].toLocaleLowerCase() == eth_address.toLocaleLowerCase()){
      dispatch({
        type: 'SET_RATE_REWARD',
        json:{data:returnValues,token_address:token_address}
      })
    }
  });
  return event
}

function user_register(userdata,dispatch) {
  return postReq('/api/v1/user/register', userdata, 'GET_USER')((data) => { 
    if (data.type == 'GET_USER_SUCCESS') {
      if (data.json.type == 'error') {
          dispatch({
            type: 'LOGIN_ERROR_CALL',
            json: {data:userdata}
          })
      } else {
        vaultContracts()(dispatch);
        dispatch({
          type: 'LOGIN_SUCCESS',
          json:{data:data.json.data}
        })
      }
    }
  })
}


export function check_login() {
  return (dispatch) => {
    if (typeof window.ethereum !== "undefined") {
      return setTimeout(() => {
        metamask_login_chk(dispatch);
      }, 1000);
    } 
  };
}

export function user_logout() {
  return (dispatch) => {
    if (typeof window.ethereum !== "undefined") {
      if (
        ethereum.isMetaMask &&
        ethereum.selectedAddress != (null || undefined)
      ) {
        return dispatch({
          type: "LOGOUT_SUCCESS",
          json: { data: {} },
        });
      }
    }
  };
}

// For getting contract info

export function vaultContracts(){
  return async(dispatch) => {
    let vaults = [
      {name:"SERIES I",nftTotal:contents.PN_STAKED_1_POOL,stakeTotal:"-",pntReward:"-",bonus:"",stakeNFT:"-",ownNft:"-",ownPNT:"-",poolNo:contents.PN_VAULT_1_POOL,contractAddr:contents.PN_VAULT_1_ADDR,erc721ContractAddr:contents.ERC721_STAKING_ADDR_1,actionType:"",actionQty:"",actionError:"",actionSuccess:"",array_order:3,image:"/assets/boxes/series-1.png"},
      {name:"SERIES II",nftTotal:contents.PN_STAKED_2_POOL,stakeTotal:"-",pntReward:"-",bonus:"",stakeNFT:"-",ownNft:"-",ownPNT:"-",poolNo:contents.PN_VAULT_2_POOL,contractAddr:contents.PN_VAULT_2_ADDR,erc721ContractAddr:contents.ERC721_STAKING_ADDR_2,actionType:"",actionQty:"",actionError:"",actionSuccess:"",array_order:2,image:"/assets/boxes/series-2.png"},
      {name:"SERIES III",nftTotal:contents.PN_STAKED_3_POOL,stakeTotal:"-",pntReward:"-",bonus:"",stakeNFT:"-",ownNft:"-",ownPNT:"-",poolNo:contents.PN_VAULT_3_POOL,contractAddr:contents.PN_VAULT_3_ADDR,erc721ContractAddr:contents.ERC721_STAKING_ADDR_3,actionType:"",actionQty:"",actionError:"",actionSuccess:"",array_order:1,image:"/assets/boxes/series-3.png"},
      {name:"SERIES IV",nftTotal:contents.PN_STAKED_4_POOL,stakeTotal:"-",pntReward:"-",bonus:"",stakeNFT:"-",ownNft:"-",ownPNT:"-",poolNo:contents.PN_VAULT_4_POOL,contractAddr:contents.PN_VAULT_4_ADDR,erc721ContractAddr:contents.ERC721_STAKING_ADDR_4,actionType:"",actionQty:"",actionError:"",actionSuccess:"",array_order:0,image:"/assets/boxes/series-4.png"},
    ]

    dispatch({
      type: 'SET_VAULTS_DATA',
      json:{data:vaults}
    })
  }
}

export function getPNContractInfo(data,eth_address){
  return async(dispatch) => {
    const pnContract = new web3.eth.Contract(PNERC721, data.contractAddr);
    const stakeContract = new web3.eth.Contract(ERC721STAKING, data.erc721ContractAddr);
    window.pnContract = pnContract;
    window.stakeContract = stakeContract;
    // let totNft = await pnContract.methods.totalSupply().call();
    
    // if nft more then supply
    data.nftTotal =  parseInt(data.nftTotal);
    // if(parseInt(totNft) > parseInt(data.nftTotal)){
    //   data.nftTotal = totNft;
    // }
    data.ownNft=await pnContract.methods.balanceOf(eth_address).call();
    let stakeInfo = await stakeContract.methods.getPoolInfoFor(data.poolNo).call();
    
    data.stakeTotal = stakeInfo.totalDeposit;
    // data.pntReward = stakeInfo.accTokenPerShare;

      stakeInfo.lastRewardBlock

    let userStakeInfo = await stakeContract.methods.getUserInfoForPool(data.poolNo,eth_address).call();
    data.stakeNFT = userStakeInfo.tokenIDs.length;
    data.ownPNT = userStakeInfo.rewardDebt;
    data.ownPNT = await stakeContract.methods.getPendingTokens(data.poolNo,eth_address).call();
    data.ownPNT = (data.ownPNT/ (10**18));
    if(data.ownPNT.toString().split(".").length > 1 ){
      data.ownPNT = data.ownPNT.toFixed(4);
    } 

      // calculation for total reward and bonus
    let infoData = await stakeContract.methods.getInfo().call();

    let outputPerBlockNumber = infoData.outputPerBlockNumber/(10**18);
    let multiplier = await stakeContract.methods.getMultiplier(infoData.startBlockNumber,stakeInfo.lastRewardBlock).call();
    let totalVaultPnt = multiplier*outputPerBlockNumber;

    // (REWARD OUTPUT PER BLOCK * INTERNAL BONUS * 6488) / totalDeposit

    let currentPntReward = totalVaultPnt/(data.stakeTotal>0 ? data.stakeTotal : 1);
    let minPntReward = totalVaultPnt/data.nftTotal;
    let bonus = 1+parseFloat(((currentPntReward-minPntReward)/minPntReward).toFixed(2));
    // data.pntReward = currentPntReward;

    data.pntReward = (outputPerBlockNumber * contents.INTERNAL_BONUS * 6488)/(data.stakeTotal>0 ? data.stakeTotal : 1);
    if(data.pntReward.toString().split(".").length > 1 ){
      data.pntReward = data.pntReward.toFixed(4);
    }

    data.bonus = bonus
    if(data.bonus.toString().split(".").length > 1 ){
      data.bonus = data.bonus.toFixed(0);
    }
    data.bonus = data.bonus+"X";
    //end info bonus

    dispatch({
      type: 'VAULT_INFO',
      json:{data:data}
    })
  } 
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let rate_event = {}; 

function unsubscribeRateEvent(address){

  try{
    rate_event[address].unsubscribe();
  }catch(e){

  }
  // if(rate_event!=null){
  //   rate_event.unsubscribe();
  //   rate_event = null;
  // }
}
export function stakeVaultTokens(data,count,user,callback){
  return async(dispatch) => {
    const eth_address = user.eth_address
    const pnContract = new web3.eth.Contract(PNERC721, data.contractAddr);
    const stakeContract = new web3.eth.Contract(ERC721STAKING, data.erc721ContractAddr);

    data.actionType ='stake';
    data.actionQty =count;
    data.actionSuccess ="";
    data.actionError ="";
    dispatch({
      type: 'VAULT_INFO',
      json:{data:data}
    })

   

  try{

    let flag = await pnContract.methods.isApprovedForAll(eth_address,data.erc721ContractAddr).call();
    if(!flag){
      await pnContract.methods.setApprovalForAll(data.erc721ContractAddr,true).send({from:eth_address});
    }

    let tokenIds =[];
    for(let i=0;i<count;i++){
        let tokenId = await pnContract.methods.tokenOfOwnerByIndex(eth_address,i).call();
        tokenIds.push(tokenId);
    }  
    
    reset_rate_reward(data.contractAddr,dispatch);
     rate_event[data.contractAddr] = read_event_rate_transfer(eth_address,data.contractAddr,data.erc721ContractAddr,dispatch); 
    let stakeInfo = await stakeContract.methods.stakeAll(data.poolNo,tokenIds).send({from:eth_address});
    let finalTx;
    do {
        finalTx = await web3.eth.getTransactionReceipt(stakeInfo.transactionHash);
        if(!finalTx.status){ 
          await sleep(2000);
        }
    }while (!finalTx.status);
     
    data.actionSuccess = finalTx;
    
    unsubscribeRateEvent(data.contractAddr);

    dispatch({
      type: 'VAULT_INFO',
      json:{data:data}
    })
    // update token
    getPNContractInfo(data,eth_address)(dispatch);
    callback('success');

  }catch(e){
    unsubscribeRateEvent(data.contractAddr);
    data.actionError =e.message;
    dispatch({
      type: 'VAULT_INFO',
      json:{data:data}
    });
    callback('error');
  }

  }
}




export function unStakeVaultTokens(data,count,user,callback){
  return async(dispatch) => {
    const eth_address = user.eth_address
    const pnContract = new web3.eth.Contract(PNERC721, data.contractAddr);
    const stakeContract = new web3.eth.Contract(ERC721STAKING, data.erc721ContractAddr);

    data.actionType ='unstake';
    data.actionQty =count;
    data.actionSuccess ="";
    data.actionError ="";
    dispatch({
      type: 'VAULT_INFO',
      json:{data:data}
    })

    
  try{
    let stakeTokenIds = await stakeContract.methods.getUserInfoForPool(data.poolNo,eth_address).call();
    let tokenIds =[];
    for(let i=0;i<count;i++){
      tokenIds.push(parseInt(stakeTokenIds.tokenIDs[i]));
    }
    reset_rate_reward(data.contractAddr,dispatch);
    rate_event[data.contractAddr] = read_event_rate_transfer(eth_address,data.contractAddr,data.erc721ContractAddr,dispatch); 
    let stakeInfo = await stakeContract.methods.unstakeAll(data.poolNo,tokenIds).send({from:eth_address});
    let finalTx;
    do {
        finalTx = await web3.eth.getTransactionReceipt(stakeInfo.transactionHash);
        if(!finalTx.status){ 
          await sleep(2000);
        }
    }while (!finalTx.status);
     
    data.actionSuccess = finalTx;
    unsubscribeRateEvent(data.contractAddr);

    dispatch({
      type: 'VAULT_INFO',
      json:{data:data}
    })
    // update token
    getPNContractInfo(data,eth_address)(dispatch);
    callback('success');

  }catch(e){
    data.actionError =e.message;
    unsubscribeRateEvent(data.contractAddr);
    dispatch({
      type: 'VAULT_INFO',
      json:{data:data}
    });
    callback('error');
  }

  }
}

export function resetStakeToken(data){
  return async(dispatch) => {
    data.actionType ="";
    data.actionQty ="";
    data.actionSuccess ="";
    data.actionError ="";
    dispatch({
      type: 'VAULT_INFO',
      json:{data:data}
    })

  }
}
