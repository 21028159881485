import React from 'react'





const BannerArea = () => {
  return (
    <>
     
     <section className="banner_area">
       <div className="container-fluid py-5 banner_container">
         <div className="row justify-content-md-center" >
           <div className="col py-5 text-center logo_container">
            <img src="/assets/react_v2_img/logo.svg" className="logo" />
           </div>
         </div>
       </div>
    </section> 
    </>
  )
}

export default BannerArea
