var BigNumber = require('big-number');
// window.BigNumber = BigNumber;
export function userReducer(state = { login: false, user: false,network:true }, action) {

  switch (action.type) {
    case "LOGIN_SUCCESS":
      let obj = { login: true, user: action.json.data,network:true }
      return obj
      break;
    case "LOGIN_ERROR_CALL_NETWORK":  
       return { login: false, user: false,network:false }
      break;
    case "LOGOUT_SUCCESS":  
       return { login: false, user: false,network:true} 
  }
  return state;
}


export function userVaultsReducer(state=[],action){
  switch (action.type) {
    case "SET_VAULTS_DATA":
      let obj = {}
      for (let index = 0; index < action.json.data.length; index++) {
        const element = action.json.data[index];
        obj[element.contractAddr] = element
      }
      return obj
      break;
    case "VAULT_INFO":  
      let data = {...state}
      data[action.json.data.contractAddr] = action.json.data;
       return data;
      break;
   
  }
  return state;
}


export function rateRewardReducer(state={},action){
  let data ={}
  switch (action.type) {
    case "RESET_RATE_REWARD":
       data = {...state}
        data[action.json.token_address]={from:null,to:null,amount:new BigNumber(0)}
         return data
      break;
    case "SET_RATE_REWARD":  
      data = {...state};
      let amount = new BigNumber(action.json.data.value.toString());
      amount = amount.plus(data[action.json.token_address].amount.toString());  
      data[action.json.token_address]={from:action.json.data.from,to:action.json.data.to,amount:amount}
      return data
     break;
  }
  return state;
}