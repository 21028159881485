import React, { useState } from "react";
var BigNumber = require('big-number');

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  stakeVaultTokens,
  unStakeVaultTokens,
  resetStakeToken,
} from "../../services/actions";
import * as constants from "../../services/constants";

const StakeDetail = (props) => {
  const { user } = props.user;
  const { data,rateReward } = props;
  const [nftCount, setNftCount1] = useState(0);
  const [timeOutVar, setTimeoutVar] = useState(null);


  const setNftCount =(v)=>{
    v = parseInt(v);
    if(v > 0){
      setNftCount1(v);
    }else{
      setNftCount1(0);
    }
  }
  
  let stakeBtnClick = () => {
    if (timeOutVar != null) {
      clearTimeout(timeOutVar);
      setTimeoutVar(null);
    }
    props.stakeVaultTokens(data, nftCount, user, (type) => {
      setNftCount("");
      let t = setTimeout(() => {
        props.resetStakeToken(data);
      }, 30000);
      setTimeoutVar(t);
    });
  };

  let unStakeBtnClick = () => {
    if (timeOutVar != null) {
      clearTimeout(timeOutVar);
      setTimeoutVar(null);
    }
    props.unStakeVaultTokens(data, nftCount, user, (type) => {
      setNftCount("");
      let t = setTimeout(() => {
        props.resetStakeToken(data);
      }, 30000);
      setTimeoutVar(t);
    });
  };

  const getRewardAmount = ()=>{
    if(rateReward[data.contractAddr].amount.gt(0)){
     return (rateReward[data.contractAddr].amount/(10**18)).toFixed(4);
    }else{
      return 0
    }
  }

  return (
    <div className="card-body custom_accordence ">
      <div className="row">
        <div className="col-md-12">
          {data.actionSuccess != "" && (
            <span className="badge bg-primary w-100 badge_box badge_success text-center my-3 text-wrap">
              Success! You {data.actionType == "stake" ? "staked" : "unstaked"}{" "}
              {data.actionQty} NFTs{getRewardAmount()>0 ? ` and claimed ${getRewardAmount()} rewards` : ""}!{" "}
              <a
                href={
                  (constants.ETH_ENV == "development"
                    ? "http://rinkeby.etherscan.io/tx/"
                    : "http://etherscan.io/tx/") +
                  data.actionSuccess.transactionHash
                }
                className="badge_success"
                target="_blank"
              >
                View On Etherscan
              </a>
            </span>
          )}

          {data.actionType == "" && (data.stakeNFT > 0 || data.ownNft > 0) && (
            <span className="badge bg-primary w-100 badge_box badge_info text-center my-3 text-wrap">
              {" "}
              {data.ownNft > 0 ? "Stake" : ""}{" "}
              {data.stakeNFT > 0 && data.ownNft > 0 ? "or" : ""}{" "}
              {data.stakeNFT > 0 ? "Unstake" : ""} NFTs to claim RATE
            </span>
          )}

          {data.actionType == "" && data.stakeNFT == 0 && data.ownNft == 0 && (
            <span className="badge bg-primary w-100 badge_box badge_info text-center my-3 text-wrap">
              {" "}
              You currently do not have any Vault NFTs to stake. Purchase a
              Polyient Network Vault NFT to get started!
            </span>
          )}

          {data.actionError != "" && (
            <span
              className="badge bg-primary w-100 badge_box badge_danger text-center my-3 text-wrap"
              style={{ overflow: "hidden" }}
            >
              Error! {data.actionError}
            </span>
          )}

          {data.actionType != "" &&
            data.actionSuccess == "" &&
            data.actionError == "" && (
              <span className="badge bg-primary w-100 badge_box badge_warning text-center my-3 text-wrap">
                {data.actionType == "stake"
                  ? "Transaction processing. Please wait while staking completes."
                  : "Transaction processing. Please wait while unstaking completes."}
              </span>
            )}
        </div>

        <div className="col-md-4 col-sm-4 col-lg-2">
          <div className="accordance_dropdown">
            <h4 >STAKED NFTs</h4>
            <h3 className="staked_nft">{data.stakeNFT}</h3>
          </div>
        </div>

        <div className="col-md-4 col-sm-4 col-lg-3">
          <div className="accordance_dropdown">
            <h4 className="text-uppercase">Available to Stake</h4>
            <h3 className="avlbl_stake">{data.ownNft}</h3>
          </div>
        </div>

        <div className="col-md-12 col-lg-7 col-sm-12  stake_unstake_area">
          <div className="stack_unstack_input float-end">
            <div className="stack_unstack_input_area">
              <div className="input-group">
                <input
                  type="number"
                  className="form-control border-0 p-2 my-2 stake_unstake_text_box"
                  placeholder="Number Of NFTs"
                  aria-label="Number Of NFTs"
                  aria-describedby="basic-addon2"
                  value={nftCount}
                  onChange={(e) => setNftCount(e.target.value)}
                  min="0"
                />
                <button
                  className="input-group-text"
                  id="basic-addon2"
                  onClick={() => {
                    setNftCount(data.ownNft > 0 ? data.ownNft : data.stakeNFT);
                  }}
                  id="basic-addon2"
                >
                  MAX
                </button>
              </div>
            </div>
            <button
              className="btn btn-primary custom-primary mx-2 stack_btn"
              onClick={stakeBtnClick}
              disabled={
                nftCount <= 0 ||
                nftCount > data.ownNft ||
                data.ownNft == 0 ||
                (data.actionType != "" &&
                  data.actionSuccess == "" &&
                  data.actionError == "")
              }
            >
              STAKE
            </button>
            <button
              className="btn btn-primary custom-primary mx-2 unstack_btn"
              onClick={unStakeBtnClick}
              disabled={
                nftCount <= 0 ||
                nftCount > data.stakeNFT ||
                data.stakeNFT == 0 ||
                (data.actionType != "" &&
                  data.actionSuccess == "" &&
                  data.actionError == "")
              }
            >
              {" "}
              UNSTAKE
            </button>
          </div>
        </div>

      
      </div>
    </div>
  );
};

const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  vaults: (state) => state.vaults,
  rateReward: (state) => state.rateReward,
});

const mapDisactcProps = {
  stakeVaultTokens,
  unStakeVaultTokens,
  resetStakeToken,
};
export default connect(structuredSelector, mapDisactcProps)(StakeDetail);
