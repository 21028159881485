import React, { useContext } from "react";
import StakeWidget from "./StakeWidget";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

const Stake = (props) => {
  const { vaults } = props;

  const valutsViews = [];
  for (const key in vaults) {
    valutsViews[vaults[key].array_order] = <StakeWidget key={key} contractAddr={key} data={vaults[key]}/>
  }

  return (
    <>
      {valutsViews} 
    </>
  );
};

const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  vaults: (state) => state.vaults,
});

const mapDisactcProps = { };
export default connect(structuredSelector, mapDisactcProps)(Stake);