import { combineReducers } from 'redux';

import { userReducer,userVaultsReducer,rateRewardReducer } from './connector';
import { faqsReducer } from './list';

const rootReducer = combineReducers({
  user: userReducer,
  vaults: userVaultsReducer,
  rateReward: rateRewardReducer,
  faqs: faqsReducer,
});

export default rootReducer;