import React from 'react'
import Iubenda from 'react-iubenda-policy'

import { Link } from 'react-router-dom'

const Footer = () => {
  const myPolicy = 71207316 // your policy id

  return (
    <>
      <footer className="py-5 footerarea">
        <div className="container">

          <div className="row py-5">
            <div className="col-md-6 px-4">
              <img src="/assets/react_v2_img/brand_logo.png" width="50%" />
              <p className="footer-text">
                Data, ratings and appraisals for the NFT asset class.
              </p>
              <div className="text-start">
                <a className="mx-2" style={{ textDecoration: 'none' }} href="https://twitter.com/rate_network" target="_blank"><img src="/assets/react_v2_img/twitter.png" width={50} /> </a>
                <a className="mx-2" style={{ textDecoration: 'none' }} href="https://www.linkedin.com/company/polyient/" target="_blank"><img src="/assets/react_v2_img/linkedin.png" width={50} /> </a>

              </div>
            </div>
            <div className="col-md-2 flink">
              <h4 className="heading-23">Press</h4>
              <ul className="footer-list">
                <li className="footer-link-wrapper-2">
                  <a className="footer-links" href="https://medium.com/polyient-network" target="_blank"> Medium </a></li>
                <li className="footer-link-wrapper-2">
                  <a className="footer-links" href="https://www.polyient.io/press" target="_blank"> Press Kit </a></li>
              </ul>
            </div>
            <div className="col-md-2 flink">
              <h4 className="heading-23">Contact</h4>
              <ul className="footer-list">
                <li className="footer-link-wrapper-2">
                  <a className="footer-links" href="mailto:hello@rate.network?subject=%5BWebsite%20Contact%5D"> hello@rate.network </a></li>
                  
                <li className="footer-link-wrapper-2">
                  <a className="footer-links" href="https://www.polyient.io/jobs" target="_blank"> Jobs </a></li>

              </ul>
            </div>
            <div className="col-md-2 flink">
              <h4 className="heading-23">Resources</h4>
              <ul className="footer-list">
                <li className="footer-link-wrapper-2">
                  <a className="footer-links" href="https://docs.polyient.network/" target="_blank"> Litepaper </a></li>
                <li className="footer-link-wrapper-2">
                  <a className="footer-links" href="https://www.rate.network/faq" target="_blank"> FAQ </a></li>
              </ul>
            </div>
          </div>
        </div>

        <h6 className="text-center">© Copyright 2021 Polyient Inc.</h6>
        <div className="container-fluid">

          <div className="row  justify-content-md-center">
            <div className="col-2 text-center">
            <Iubenda id={myPolicy} type='privacy' className="text-white">
              Privacy Policy  
            </Iubenda>
              
            </div>
            <div className="col-2 text-center">
            <Iubenda id={myPolicy} type='terms-and-conditions' className="text-white">
                Terms and Conditions  
            </Iubenda> 
            </div>
            <div className="col-2 text-center">
            <Iubenda id={myPolicy} type='cookie' className="text-white">
                Cookie Policy  
            </Iubenda> 
             
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
