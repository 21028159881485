import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { metamaskLogin, check_login } from "../../services/actions";

import BannerArea from "../../component/BannerArea";

import * as constants from "../../services/constants";
import NetworkError from "../../component/popups/NetworkError";

import Header from "../../component/Header"
import Footer from "../../component/Footer"

const Login = (props) => {
  const { user } = props;
  const [errorPopup, setErrorPopup] = useState(!user.network);

  useEffect(() => {
    setErrorPopup(!user.network);
  }, [user.network]);

  const handleClick = () => {
    constants.GPageEvent("Login", "Metamask", "UserLogin");
    chkEthNetowrk();
    if (user.network) {
      if (typeof window.ethereum !== "undefined") {
        props.metamaskLogin();
      } else {
        window.open(
          "https://metamask.app.link/dapp/" +
            constants.DOMAIN_NAME.replace("https://", "").replace(
              "http://",
              ""
            ) +
            ""
        );
      }
    } else {
      chkEthNetowrk();
    }
  };

  const chkEthNetowrk = () => {
    setErrorPopup(!user.network);
  };
  return (
    <div className="main_div">
    <Header />
      <BannerArea />
      
      {errorPopup && <NetworkError close={setErrorPopup} />}

      <div className="page-content" >
        <div className="container">
          <div calss="row justify-content-md-center">
            <div className="mx-auto col-xl-3-5 col-lg-5 col-md-6 col-sm-12 py-5 partical_com">
              <div
                className=" text-center"
                style={{ border: "1px solid #8E85B1" }}
              >
                <div className="  mx-auto py-3 border-0 px-5 d-grid">
                  <h4 className="text-center heading-2">
                    <div className="login_img_holder my-3">
                      <img src="/assets/img/logo.png" width={80} />
                    </div>
                    Welcome to the <br />
                    RATE Vault
                  </h4>

                  <div className=" border-0  d-block px-0 mx-auto my-3">
                    <button
                      type="button"
                      className="btn btn-primary  ml-0 border-0 text-white gredient_btn py-3 login_gredient_btn"
                      onClick={handleClick}
                    >
                      <img
                        src="/assets/img/metamask.svg"
                        alt="MetaMask logo "
                        className="mx-1 mmask_img"
                      />
                      Connect MetaMask
                    </button>
                  </div>
                  <div className="  mx-auto py-3 border-0 px-5 d-grid">
                    <p className="paragraph home-hero-v1  main_text">
                      Need MetaMask?{" "}
                      <a
                        href="https://metamask.io/"
                        target="_blank"
                        className="main_text"
                      >
                        Get it here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
});
const mapDisactcProps = { metamaskLogin, check_login };
export default connect(structuredSelector, mapDisactcProps)(Login);
