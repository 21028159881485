import React from 'react'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'

const Header = (props) => {
    return (
        <section >
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark px-3 pb-3" style={{ paddingTop: "1rem" }}>
                    <div className="container-fluid">
                        <div className="col-8">
                            <a href="https://rate.network/" className="navbar-brand " target="_blank"><img src="./assets/react_v2_img/brand_logo.png" width={210} sizes="(max-width: 479px) 71vw, 260px" alt="" className="header-logo" /> </a>

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">

                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 text-dark">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link  nav_links active"> Vaults </Link>

                                </li>
                                <li className="nav-item">
                                    <a target="_blank" href="https://www.rate.network/faq" className="nav_links nav-link ">FAQ </a>
                                </li>
                                <li className="nav-item">
                                    <a href="https://docs.polyient.network/" target="_blank" className="nav_links nav-link ">Docs </a>

                                </li>
                                <li className="nav-item">
                                    <a href="https://www.polyient.io/press" target="_blank" className="nav_links nav-link ">Press </a>

                                </li>
                                <li className="nav-item">
                                    <a href="https://www.polyient.io/jobs" target="_blank" className="nav_links nav-link ">Jobs </a>

                                </li>
                            </ul>

                        </div>
                    </div>
                </nav>
            </div>
        </section>
    )
}

Header.propTypes = {

}

export default Header
